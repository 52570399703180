import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createItemsApi from "./ItemsService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

const initialState = {
  getAllItems: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleItem: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateItem: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addItem: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteItem: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getPosItems: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getBarCode: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

const BASE = "item-details";

export const ActionTypes = {
  GET_ALL_ITEMS: `${BASE}/get-all`,
  GET_POS_ITEMS: `${BASE}/get-pos-items`,
  ADD_ITEM: `${BASE}`,
};

const itemsService = createItemsApi("item-details");

export const getAllItems = createAsyncThunk(
  ActionTypes.GET_ALL_ITEMS,
  async ({ payload }, thunkAPI) => {
    const response = await itemsService.getAllItems(payload);

    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  `${BASE}/itemId`,
  async ({ itemId, vItemId }, thunkAPI) => {
    const response = await itemsService.getSingleItem(itemId, vItemId);
    return response?.data?.data;
  }
);

export const updateItem = createAsyncThunk(
  `${BASE}/itemId/update`,
  async ({ itemId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await itemsService.updateItem(itemId, payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Item updated successfully!");
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getPosItems = createAsyncThunk(
  ActionTypes.GET_POS_ITEMS,
  async ({ payload }, thunkAPI) => {
    const response = await itemsService.getPosItems(payload);
    return response?.data?.data;
  }
);

export const addItem = createAsyncThunk(
  ActionTypes.ADD_ITEM,
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await itemsService.addItem(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Item added successfully!");
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getBarCode = createAsyncThunk(
  "getBarCode",
  async ({  isVariable }, thunkAPI) => {
    const response = await itemsService.getBarCode(
      isVariable
    );
    console.log('response :', response);
    return response?.data?.data;
  }
);

export const deleteItem = createAsyncThunk(
  `${BASE}/itemId?vItem={id}`,
  async ({ itemId, vItemId, successCallBack }, thunkAPI) => {
    try {
      const response = await itemsService.deleteItem(itemId, vItemId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Item deleted successfully!", "success", async () => {
          const undoResponse = await itemsService.undoItem(itemId, vItemId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllItems({ payload }));
          }
        });
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const itemsSlice = createSlice({
  name: "items",
  initialState,

  reducers: {
    reset: (state) => {
      state.getAllItems = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getPosItems = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleItem = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getBarCode = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllItems.pending, (state) => {
        state.getAllItems.isLoading = true;
        state.getAllItems.message = "";
        state.getAllItems.isError = false;
        state.getAllItems.isSuccess = false;
        state.getAllItems.data = null;
      })
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.getAllItems.isLoading = false;
        state.getAllItems.isSuccess = true;
        state.getAllItems.data = action.payload.data;
        state.getAllItems.totalPages = action.payload.totalPages;
        state.getAllItems.totalRecords = action.payload.TotalRecords;
      })
      .addCase(getAllItems.rejected, (state, action) => {
        state.getAllItems.message = action.payload?.message;
        state.getAllItems.isLoading = false;
        state.getAllItems.isError = true;
        state.getAllItems.data = null;
      })
      .addCase(getSingleItem.pending, (state) => {
        state.getSingleItem.isLoading = true;
        state.getSingleItem.message = "";
        state.getSingleItem.isError = false;
        state.getSingleItem.isSuccess = false;
        state.getSingleItem.data = null;
      })
      .addCase(getSingleItem.fulfilled, (state, action) => {
        state.getSingleItem.isLoading = false;
        state.getSingleItem.isSuccess = true;
        state.getSingleItem.data = action.payload;
      })
      .addCase(getSingleItem.rejected, (state, action) => {
        state.getSingleItem.message = action.payload?.message;
        state.getSingleItem.isLoading = false;
        state.getSingleItem.isError = true;
        state.getSingleItem.data = null;
      })
      .addCase(addItem.pending, (state) => {
        state.addItem.isLoading = true;
        state.addItem.message = "";
        state.addItem.isError = false;
        state.addItem.isSuccess = false;
        state.addItem.data = null;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        state.addItem.isLoading = false;
        state.addItem.isSuccess = true;
        state.addItem.data = action.payload;
      })
      .addCase(addItem.rejected, (state, action) => {
        state.addItem.message = action.payload?.message;
        state.addItem.isLoading = false;
        state.addItem.isError = true;
        state.addItem.data = null;
      })
      .addCase(getPosItems.pending, (state) => {
        state.getPosItems.isLoading = true;
        state.getPosItems.message = "";
        state.getPosItems.isError = false;
        state.getPosItems.isSuccess = false;
        state.getPosItems.data = null;
      })
      .addCase(getPosItems.fulfilled, (state, action) => {
        state.getPosItems.isLoading = false;
        state.getPosItems.isSuccess = true;
        state.getPosItems.data = action.payload;
      })
      .addCase(getPosItems.rejected, (state, action) => {
        state.getPosItems.message = action.payload?.message;
        state.getPosItems.isLoading = false;
        state.getPosItems.isError = true;
        state.getPosItems.data = null;
      })
      .addCase(getBarCode.pending, (state) => {
        state.getBarCode.isLoading = true;
        state.getBarCode.message = "";
        state.getBarCode.isError = false;
        state.getBarCode.isSuccess = false;
        state.getBarCode.data = null;
      })
      .addCase(getBarCode.fulfilled, (state, action) => {
        state.getBarCode.isLoading = false;
        state.getBarCode.isSuccess = true;
        state.getBarCode.data = action.payload;
      })
      .addCase(getBarCode.rejected, (state, action) => {
        state.getBarCode.message = action.payload?.message;
        state.getBarCode.isLoading = false;
        state.getBarCode.isError = true;
        state.getBarCode.data = null;
      });
  },
});

export const { reset: resetItems } = itemsSlice.actions;

export default itemsSlice.reducer;
