import api from "../../services/api";

/**
 * Creates categories API endpoints based on the specified base path.
 * @param {string} basePath - The base path for categories endpoints.
 * @returns {Object} Object containing categories API methods.
 */
const createCategoriesApi = (basePath) => ({
  /**
   * get all categories
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting categories
   *    order: order for sorting categories by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the categories response.
   */
  getAllCategories: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single category
   * @param {object} categoryId - id of the category
   * @returns {Promise} A Promise that resolves to the category response.
   */
  getSingleCategory: async (categoryId) => {
    const response = await api().get(`${basePath}/${categoryId}`);
    return response;
  },

  /**
   * update a single category
   * @param {object} categoryId - id of the category
   * @returns {Promise} A Promise that resolves to the category response.
   */
  updateCategory: async (categoryId, payload) => {
    const response = await api().put(`${basePath}/${categoryId}`, payload);
    return response;
  },

  /**
   * add a category
   * @param {object} categoryId - id of the category
   * @returns {Promise} A Promise that resolves to the category response.
   */
  addCategory: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a category
   * @param {object} categoryId - id of the category
   * @returns {Promise} A Promise that resolves to the category response.
   */
  deleteCategory: async (categoryId) => {
    const response = await api().delete(`${basePath}/${categoryId}`);
    return response;
  },
  /**
   * undo a deleted  category
   * @param {object} categoryId - id of the category
   * @returns {Promise} A Promise that resolves to the category response.
   */
  undoCategory: async (categoryId) => {
    const response = await api().get(
      `${basePath}/undo-categories/${categoryId}`
    );
    return response;
  },
});

// Export the createCategoriesApi

/**
 * Example usage:
 * import createCategoriesApi from './path/to/api/categoriesApi';
 *
 * const categoriesApi = createCategoriesApi('categories');
 *
 * const getAllCategories = async (payload) => {
 *   try {
 *     const response = await categoriesApi.getAllCategories(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createCategoriesApi;
