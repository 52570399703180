/**
 * Manages state for stores.
 * Defines initial state for each store action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createStoresApi from "./StoresService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to stores processes.
 * Each property represents a specific stores action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllStores, addStore, deleteStore, etc.
 * store data, error, success, loading, and message status for corresponding stores actions.
 */
const initialState = {
  getAllStores: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleStore: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addStore: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the stores process.
 * These action types are prefixed with the 'stores' base path for better organization.
 */
const BASE = "stores";

export const ActionTypes = {
  GET_ALL_STORES: `${BASE}/get-all`, // Action type for get all stores
  ADD_STORE: `${BASE}`, // Action type for adding a store
};

// Creating an instance of the stores service with a base URL 'stores'
const storesService = createStoresApi("stores");

/**
 * Initiates the getAllStores process
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting stores
 *    order: order for sorting stores by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllStores.
 */
export const getAllStores = createAsyncThunk(
  ActionTypes.GET_ALL_STORES,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await storesService.getAllStores(payload);
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getSingleStore process for a store.
 * @param {object} storeId id of the store
 * @param {function} successCallBack - Callback function upon successful getSingleStore.
 */
export const getSingleStore = createAsyncThunk(
  `${BASE}/storeId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ storeId }, thunkAPI) => {
    const response = await storesService.getSingleStore(storeId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updateStore process for a store.
 * @param {object} storeId id of the store
 * @param {object} payload
   *    branchName: name of the branch,
        streetName: street name of the store
        buildingNumber: build number of the store,
        districtName: district name,
        city: city name of the store,
        zipCode: zip code,
        isAccessToDashboard: access to dashboard,
        isAccessToPos: access to pos
}
 * @param {function} successCallBack - Callback function upon successful updateStore.
 */
export const updateStore = createAsyncThunk(
  `${BASE}/storeId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ storeId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await storesService.updateStore(storeId, payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Store updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addStore process for a store.
 * @param {object} storeId id of the store
 * @param {object} payload
   *    branchName: name of the branch,
        streetName: street name of the store
        buildingNumber: build number of the store,
        districtName: district name,
        city: city name of the store,
        zipCode: zip code,
        isAccessToDashboard: access to dashboard,
        isAccessToPos: access to pos
}
 * @param {function} successCallBack - Callback function upon successful addStore.
 */
export const addStore = createAsyncThunk(
  ActionTypes.ADD_STORE,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await storesService.addStore(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Store added successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteStore process for a store.
 * @param {object} storeId id of the store
 * @param {function} successCallBack - Callback function upon successful addStore.
 */
export const deleteStore = createAsyncThunk(
  `${BASE}/storeId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ storeId, successCallBack }, thunkAPI) => {
    try {
      const response = await storesService.deleteStore(storeId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Store deleted successfully!", "success", async () => {
          const undoResponse = await storesService.undoStore(storeId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllStores({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for stores related state management.
 * - `name`: Name of the slice (stores)
 * - `initialState`: Initial state defining properties for various stores actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllStores, updateStore etc).
 */
export const storesSlice = createSlice({
  name: "stores",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllstore action.
     */
    reset: (state) => {
      state.getAllStores = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleStore = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllStores action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllStores.pending, (state) => {
        state.getAllStores.isLoading = true;
        state.getAllStores.message = "";
        state.getAllStores.isError = false;
        state.getAllStores.isSuccess = false;
        state.getAllStores.data = null;
      })
      /**
       * Updates the state when getAllStores action is fulfilled/successful.
       * Updates loading and success flags and sets getAllStores data with the payload.
       */
      .addCase(getAllStores.fulfilled, (state, action) => {
        state.getAllStores.isLoading = false;
        state.getAllStores.isSuccess = true;
        state.getAllStores.data = action.payload.data;
        state.getAllStores.totalPages = action.payload.totalPages;
        state.getAllStores.totalRecords = action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllStores action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllStores.rejected, (state, action) => {
        state.getAllStores.message = action.payload?.message;
        state.getAllStores.isLoading = false;
        state.getAllStores.isError = true;
        state.getAllStores.data = null;
      })
      /**
       * Updates the state while the getSingleStore action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleStore.pending, (state) => {
        state.getSingleStore.isLoading = true;
        state.getSingleStore.message = "";
        state.getSingleStore.isError = false;
        state.getSingleStore.isSuccess = false;
        state.getSingleStore.data = null;
      })
      /**
       * Updates the state when getSingleStore action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleStore data with the payload.
       */
      .addCase(getSingleStore.fulfilled, (state, action) => {
        state.getSingleStore.isLoading = false;
        state.getSingleStore.isSuccess = true;
        state.getSingleStore.data = action.payload;
      })
      /**
       * Updates the state when getSingleStore action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleStore.rejected, (state, action) => {
        state.getSingleStore.message = action.payload?.message;
        state.getSingleStore.isLoading = false;
        state.getSingleStore.isError = true;
        state.getSingleStore.data = null;
      })
      /**
       * Updates the state while the addStore action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addStore.pending, (state) => {
        state.addStore.isLoading = true;
        state.addStore.message = "";
        state.addStore.isError = false;
        state.addStore.isSuccess = false;
        state.addStore.data = null;
      })
      /**
       * Updates the state when addStore action is fulfilled/successful.
       * Updates loading and success flags and sets addStore data with the payload.
       */
      .addCase(addStore.fulfilled, (state, action) => {
        state.addStore.isLoading = false;
        state.addStore.isSuccess = true;
        state.addStore.data = action.payload;
      })
      /**
       * Updates the state when addStore action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addStore.rejected, (state, action) => {
        state.addStore.message = action.payload?.message;
        state.addStore.isLoading = false;
        state.addStore.isError = true;
        state.addStore.data = null;
      });
  },
});

/**
 * Destructures the reset action from the storesSlice actions.
 * - `reset`: Action function to reset the stores related state.
 */

// sample usage

// import { reset } from './storesSlice';

// Using the reset action
// const resetStores = () => {
//   // Dispatching the reset action to reset stores state
//   store.dispatch(reset());
// };

export const { reset } = storesSlice.actions;

/**
 * Exports the default reducer generated by storesSlice.
 * This reducer handles state updates for stores related actions.
 */

// sample usage

// import storesReducer from "./storesSlice";

// Using the default reducer
// const initialState = {
// Your initial state for stores actions
// };

// Creating a store with the storeReducer handling stores related actions
// const store = configureStore({
// reducer: {
// stores: storesReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default storesSlice.reducer;
